import { ReactComponent as HooplaLogo } from '../../images/logo.svg';
import { ReactComponent as HooplaSparksIcon } from '../../images/logo_sm.svg';
import { ReactComponent as HooplaCircledLogo } from '../../images/hoopla_circled_logo.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { ReactComponent as MenuIcon } from '../../icons/menu_icon.svg';
import { ReactComponent as TicketsIcon } from '../../icons/tickets_icon.svg';
import { ReactComponent as LogOutIcon } from '../../icons/logout_icon.svg';
import { ReactComponent as CheckInIcon } from '../../icons/check_in_icon.svg';
import { ReactComponent as DownArrowIcon } from '../../icons/down_arrow_icon.svg';
import { ReactComponent as AppStoreIcon } from '../../icons/appstore_badge.svg';
import { ReactComponent as HooplaIcon } from '../../images/hooplaicon.svg';
import GooglePlayIcon from '../GooglePlayIcon/GooglePlayIcon';
import { RoutesEnum } from '../../core/enums';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useStores } from '../../hooks';
import { getMediaFile, logOut } from '../../core/api';
import DropdownMenu, { ListItemProps } from '../DropdownMenu/DropdownMenu';
import Avatar from '../Avatar/Avatar';
import { ReactNode, useMemo, useState } from 'react';
import { useAuthModal } from '../Modal/AuthModal/AuthModal';
import {
  iOS,
  isAndroid,
  isMobileDevice,
  numFormatter
} from '../../core/helpers';
import appToast from '../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import Loader from '../Loader/Loader';
import { useMessenger } from '../Messenger/Messenger';
import { PRO_FEAT_MESSAGE, SUPER_USERS } from '../../core/consts';
import { downloadApp } from '../DownloadWidget/DownloadWidget.helper';
import HeaderNav from './HeaderNav';
import AppModal from '../Modal/AppModal';
import './Header.scss';

interface Props {
  className?: string;
  subSection?: ReactNode;
}

const Header = ({ className = '', subSection }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userStore,
    likedMedia,
    stripeStore,
    bookingsStore,
    followUsers,
    locationStore,
    appDataManager
  } = useStores();
  const { showAuthModal } = useAuthModal();
  const { closeAllAtiveChats } = useMessenger();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const logOutUser = async () => {
    try {
      setIsLoggingOut(true);
      await logOut();

      closeAllAtiveChats();

      userStore.saveUserToStore(undefined);
      userStore.saveAddress(undefined, undefined, undefined);
      userStore.savePaymentMethods([]);
      likedMedia.saveToStore([]);
      stripeStore.setClientSecret(undefined);
      stripeStore.setPublicKey(undefined);
      bookingsStore.saveToStore([]);
      followUsers.saveToStore([]);

      appToast.showSuccess('You are now logged out!');
    } catch (error) {
      appToast.showError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const handleShowSignInModal = () => {
    showAuthModal('sign_in');
  };

  const handleShowUpgradeModal = () => {
    setShowUpgradeModal(true);
  };

  const handleCloseUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  const handleAddEvent = () => {
    if (!userStore.user) {
      return handleShowSignInModal();
    }

    if (!userStore.user.isProviderPro && !userStore.user.isCreatorPro) {
      return handleShowUpgradeModal();
    }

    navigate(RoutesEnum.CreateEvent);
  };

  const userMenu: ListItemProps[] = useMemo<ListItemProps[]>(() => {
    const navMenu: ListItemProps[] = [];

    const goToHome = () => navigate(RoutesEnum.Root);
    const goToProfile = () => navigate(`/${userStore.user.userName}`);
    const goToCreateEvent = () => navigate(RoutesEnum.CreateEvent);
    const goToBookings = () => navigate(RoutesEnum.Bookings);
    const goToDownloadTickets = () => navigate(RoutesEnum.GuestTicketsSignIn);
    const goToCheckIn = () => navigate(RoutesEnum.EventsManager);
    const handleDownloadApp = () =>
      downloadApp(iOS() ? 'ios' : isAndroid() ? 'android' : null);

    /**
     * Add menu items in this order
     */
    navMenu.push({
      name: 'Explore',
      callback: goToHome,
      icon: <HooplaSparksIcon />
    });

    if (userStore.user) {
      navMenu.push({
        name: 'Profile',
        callback: goToProfile,
        icon: <UserIcon />
      });

      // if (userStore.user.isProviderPro || userStore.user.isCreatorPro) {
      //   navMenu.push({
      //     name: 'Create Event',
      //     callback: goToCreateEvent,
      //     icon: <CreateEventIcon />,
      //     className: 'Header__hideOnSmallDevices'
      //   });
      // }

      if (SUPER_USERS.includes(userStore.user.userName.toLowerCase())) {
        navMenu.push({
          name: 'Check-In',
          callback: goToCheckIn,
          icon: <CheckInIcon />
        });
      }
    }

    navMenu.push({
      name: userStore.user ? 'Your Tickets' : 'Download your Tickets',
      callback: userStore.user ? goToBookings : goToDownloadTickets,
      icon: <TicketsIcon />
    });

    navMenu.push({
      name: 'Download App',
      callback: isMobileDevice() ? handleDownloadApp : null,
      list: [
        {
          name: <AppStoreIcon />,
          callback: () => downloadApp('ios')
        },
        {
          name: <GooglePlayIcon />,
          callback: () => downloadApp('android')
        }
      ],
      icon: <DownArrowIcon />,
      className: 'Header__showOnSmallDevices'
    });

    if (userStore.user) {
      navMenu.push({
        name: 'Log out',
        callback: logOutUser,
        icon: <LogOutIcon />,
        className: 'Header__showOnSmallDevices'
      });
    } else {
      navMenu.push({
        name: 'Sign In',
        callback: handleShowSignInModal,
        icon: <LogOutIcon />,
        className: 'Header__showOnSmallDevices'
      });
    }

    return navMenu;
  }, [userStore.user]);

  return (
    <>
      {isLoggingOut && <Loader />}

      <div className={classNames('Header', className)}>
        <div className='Header__wrapper'>
          <div className='Header__top'>
            <Link className='Header__logoContainer' to={RoutesEnum.Root}>
              {isMobileDevice() ? (
                <HooplaCircledLogo className='Header__logo--small' />
              ) : (
                <HooplaLogo className='Header__logo' />
              )}
            </Link>

            <div className='Header__rightSection'>
              <HeaderNav
                isAuth={!!userStore.user}
                onAddEvent={handleAddEvent}
                onSignIn={handleShowSignInModal}
                onLogout={logOutUser}
              />

              <DropdownMenu
                className='Header__userMenu'
                header={
                  userStore.user ? (
                    <div
                      className='Header__userCard mb-1'
                      onClick={() => {
                        navigate(`/${userStore.user.userName}`);
                      }}
                    >
                      <Avatar
                        className='Header__user mb-3'
                        size={isMobileDevice() ? 'small' : 'medium'}
                        symbol={userStore.user.userName[0]}
                        image={
                          userStore.user.image && (
                            <img
                              src={getMediaFile(userStore.user.image)}
                              alt={userStore.user.userName}
                            />
                          )
                        }
                      />
                      <div className='Header__userName oneLineText'>
                        {userStore.user.fullName}
                      </div>
                      {userStore.followers?.length > 0 && (
                        <div className='Header__userFollowers'>
                          {numFormatter(userStore.followers.length)} followers
                        </div>
                      )}
                    </div>
                  ) : null
                }
                list={userMenu}
                align='left'
              >
                <div className='Header__userMenuTrigger'>
                  <MenuIcon />
                </div>
              </DropdownMenu>
            </div>
          </div>

          {subSection && (
            <div className='Header__bottomSection'>{subSection}</div>
          )}
        </div>
      </div>
      <AppModal
        isOpened={showUpgradeModal}
        icon={<HooplaIcon />}
        title='Upgrade to Pro'
        description={PRO_FEAT_MESSAGE}
        close={handleCloseUpgradeModal}
      />
    </>
  );
};

export default Header;
