import { Marker, Circle } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import {
  calculateDistanceInMiles,
  getLatLngFromAddress
} from '../../core/helpers';
import { Address, ICoordinates } from '../../core/types';
import Map from '../Map/Map/Map';
import './EventLocation.scss';
import { observer } from 'mobx-react-lite';
import Loader from '../Loader/Loader';
import { useStores } from '../../hooks';

interface Props {
  className?: string;
  placeId?: string;
  coords?: ICoordinates;
  serviceRadius?: number;
  displayAddress?: boolean;
  displayDistance?: boolean;
  address?: Address;
}

const EventLocation = ({
  serviceRadius,
  coords,
  placeId = '',
  displayAddress = true,
  displayDistance = false,
  address,
  className = ''
}: Props) => {
  const { userStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [canDisplayMap, setCanDisplayMap] = useState(true);
  const [coordsFromAddress, setCoordsFromAddress] =
    useState<ICoordinates>(null);

  useEffect(() => {
    validateMapData();
  }, [coords, address]);

  const validateMapData = async () => {
    if (!address) {
      setCanDisplayMap(false);
      return;
    }

    setIsLoading(true);

    if (!coords?.lat || !coords?.lng) {
      const fullAddress = Object.values(address).filter(Boolean).join(', ');
      const response = await getLatLngFromAddress(fullAddress);
      setCoordsFromAddress(response?.results[0]?.geometry?.location);
    }

    setIsLoading(false);
  };

  const openLocation = (e: React.MouseEvent<HTMLDivElement>) => {
    const _coords = coordsFromAddress ?? coords;

    const locationUrl = `https://www.google.com/maps/search/?api=1&query=${_coords?.lat},${_coords?.lng}&query_place_id=${placeId}`;
    window.open(locationUrl, '_blank');
  };

  const distanceBetween =
    displayDistance && coords && userStore.coords
      ? calculateDistanceInMiles(userStore.coords, coords)
      : null;

  const cityState = displayAddress
    ? [address?.city, address?.state].filter(Boolean).join(', ')
    : null;

  console.log({ coordsFromAddress, coords });

  return (
    canDisplayMap && (
      <div className={`EventLocation ${className}`}>
        {isLoading ? (
          <Loader fixed={false} showLogo={false} width='32px' />
        ) : (
          <>
            {displayAddress && (
              <>
                <div className='EventLocation__location'>
                  {address?.locationName && (
                    <div className='EventLocation__address'>
                      {address?.locationName}
                    </div>
                  )}

                  {address?.address2 && (
                    <div className='EventLocation__address'>
                      {address?.address2}
                    </div>
                  )}

                  <div className='EventLocation__address'>
                    {address?.address}
                  </div>

                  {cityState && (
                    <div className='EventLocation__address'>
                      {`${cityState} ${address.zip ?? ''}`}
                    </div>
                  )}
                </div>
              </>
            )}

            <div className='EventLocation__inner' onClick={openLocation}>
              <div className='EventLocation__map'>
                {(coordsFromAddress || coords) && (
                  <Map
                    center={coordsFromAddress ?? coords}
                    zoom={serviceRadius ? 17.5 : 14}
                  >
                    {serviceRadius ? (
                      <Circle
                        center={coordsFromAddress ?? coords}
                        radius={serviceRadius}
                        options={{
                          strokeColor: '#7741de',
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: '#7741de',
                          fillOpacity: 0.35,
                          clickable: false,
                          draggable: false,
                          editable: false,
                          visible: true,
                          radius: serviceRadius,
                          zIndex: 1
                        }}
                      />
                    ) : (
                      <Marker
                        position={coordsFromAddress ?? coords}
                        icon={{
                          url: require('../../icons/location_marker.svg')
                            .default
                        }}
                      />
                    )}
                  </Map>
                )}
              </div>

              {displayDistance && distanceBetween && (
                <div className='EventLocation__distance'>
                  {distanceBetween.toFixed()}mi away from you
                </div>
              )}
            </div>
          </>
        )}
      </div>
    )
  );
};

export default observer(EventLocation);
