import { makeAutoObservable } from 'mobx';
import { UserViewModel } from '../core/backend/models';

class ProfileStore {
  user: UserViewModel | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  saveProfile = (user: UserViewModel | undefined) => {
    this.user = user;
  };
}

export default ProfileStore;
