import Avatar from '../../Avatar/Avatar';
import {
  ProviderSummaryResponse,
  UserViewModel
} from '../../../core/backend/models';
import { Link } from 'react-router-dom';
import { getMediaFile } from '../../../core/api';
import './CreatorCard.scss';
import { numFormatter } from '../../../core/helpers';
import Loader from '../../Loader/Loader';
import { useStores } from '../../../hooks';
import FollowButton from '../../FollowButton/FollowButton';
import { observer } from 'mobx-react-lite';

interface IProps {
  creator: UserViewModel;
  className?: string;
}

const CreatorCard = ({ creator, className = '' }: IProps) => {
  const { userStore, followUsers } = useStores();

  return (
    <div className={`CreatorCard ${className}`}>
      <div className='CreatorCard__personalInfo'>
        <Link to={`/${creator.userName}`}>
          <Avatar
            className='CreatorCard__avatar'
            image={
              creator.image && (
                <img src={getMediaFile(creator.image)} alt={creator.fullName} />
              )
            }
            symbol={creator.fullName[0]}
            showProBadge={creator.isProviderPro}
          />
        </Link>

        <div className='CreatorCard__username'>
          <Link className='twoLinesText' to={`/${creator.userName}`}>
            {creator.userName}
          </Link>
        </div>
      </div>

      {(!userStore.user || userStore.user.id !== creator.id) && (
        <FollowButton
          className='CreatorCard__followButton'
          theme='light'
          size='sm'
          user={creator}
          isFollowing={
            userStore.user
              ? followUsers.isFollowingUser(userStore.user.id, creator.id)
              : false
          }
        />
      )}
    </div>
  );
};

export default observer(CreatorCard);
