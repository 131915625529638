import Modal, { IModalProps } from './Modal';
import './AppModal.scss';
import Button from '../Button/Button';

export interface IProps extends IModalProps {
  icon: JSX.Element | string;
  title: string;
  description?: string;
}

const AppModal = ({
  icon,
  title,
  description = '',
  isOpened,
  children,
  ...restProps
}: IProps) => {
  return (
    <Modal
      isOpened={isOpened}
      full={false}
      showCloseButton={false}
      {...restProps}
    >
      <div className='modal d-flex flex-column text-center justify-content-center'>
        <div className='modal__emoji' role='img' aria-label='emotion'>
          {icon}
        </div>
        <div className='modal__title'>{title}</div>
        {description && <div className='modal__text'>{description}</div>}
      </div>
      {children && (
        <div className='modal__custom-content d-flex flex-column justify-content-center'>
          {children}
        </div>
      )}
      <div className='modal__buttons'>
        <Button size='sm' variant='outline' onClick={restProps.close}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default AppModal;
