import { Link } from 'react-router-dom';
import { getMediaFile } from '../../core/api';
import { UserViewModel } from '../../core/backend/models';
import './UserCard.scss';
import Avatar from '../Avatar/Avatar';

interface IProps {
  user: UserViewModel;
  className?: string;
}

const UserCard = ({ user, className = '' }: IProps) => {
  return (
    <div className={`UserCard ${className}`}>
      <Link className='UserCard__link' to={`/${user.userName}`}>
        <Avatar
          className='UserCard__image'
          size='medium'
          image={
            user.image && (
              <img src={getMediaFile(user.image)} alt={user.userName} />
            )
          }
          symbol={user.userName[0]}
        />
        <div className='UserCard__details'>
          <div className='UserCard__userName'>@{user.userName}</div>
          <div className='UserCard__fullName'>{user.fullName}</div>
        </div>
      </Link>
    </div>
  );
};

export default UserCard;
