import { ReactComponent as HooplaLogo } from '../../../images/logo_sm.svg';
import './ClipCard.scss';
import classNames from 'classnames';
import ViewsBadge from '../../ViewsBadge/ViewsBadge';
import { IVideoModel } from '../../../core/types';
import HoverVideoPlayer from '../../HoverVideoPlayer/HoverVideoPlayer';
import LazyLoad from 'react-lazyload';
import Avatar from '../../Avatar/Avatar';
import { getMediaFile } from '../../../core/api';
import { Link } from 'react-router-dom';
import usePreloadVideoInView from '../../../hooks/usePreloadVideoInView';

interface IProps {
  clip: IVideoModel;
  className?: string;
  onPress: React.MouseEventHandler;
  compact?: boolean;
}

const ClipCard = ({
  clip,
  onPress,
  className = 'col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2',
  compact = false
}: IProps) => {
  const url = clip.mediaFiles[0]?.isVideo ? clip.mediaFiles[0]?.url : null;
  const { viewRef } = usePreloadVideoInView({ url });

  return (
    <div
      ref={viewRef}
      className={classNames(`ClipCard ${className}`, {
        'ClipCard--compact': compact
      })}
    >
      <div className='ClipCard__container'>
        <HoverVideoPlayer
          className='ClipCard__hoverVideo'
          videoSrc={clip.thumbnails.video}
          pausedOverlay={
            clip.thumbnails?.static ? (
              <LazyLoad height='100%' once>
                <img
                  className='ClipCard__thumbnail'
                  src={clip.thumbnails.static || clip.thumbnails.animated}
                  alt={clip.description}
                />
              </LazyLoad>
            ) : (
              <div className='ClipCard__placeholder'>
                <HooplaLogo />
              </div>
            )
          }
          onClick={onPress}
        />
      </div>

      <div className='ClipCard__bottom'>
        <Link to={`/${clip.owner?.userName}`}>
          <Avatar
            className='ClipCard__picture'
            size='small'
            showBorder={false}
            showShadow={true}
            image={
              clip.owner?.image && (
                <img
                  src={getMediaFile(clip.owner?.image)}
                  alt={clip.owner?.fullName ?? ''}
                />
              )
            }
            symbol={clip.owner?.fullName[0]}
          />
        </Link>
        <div className='ClipCard__details'>
          <Link className='ClipCard__link' to={`/${clip.owner?.userName}`}>
            <div className='ClipCard__userName oneLineText'>
              {clip.owner?.userName}
            </div>
          </Link>
          <ViewsBadge
            className='ClipCard__views'
            viewsCount={clip.views}
            darkTheme
          />
        </div>
      </div>
    </div>
  );
};

export default ClipCard;
