import { AxiosResponse } from 'axios';
import axios from './axios';
import {
  EventResponse,
  EventsByCategoryResponse,
  GetDiscoveryClipsResponse,
  GetEventsByHashtagKeyResponse,
  GetEventsByHighlightKeyResponse,
  GetHighlightBannersOutput
} from './models';
import { DiscoveryFilterRequest } from './interfaces';
import { BannerViewModel, GetFindMyTicketsResponse } from '../backend/models';

axios.defaults.headers.post['Api-Version'] = 2;

export const discovery = {
  /**
   * Get new events
   * @param filters
   * @returns EventResponse
   */
  getNewEvents: async (filters: DiscoveryFilterRequest) => {
    const response: AxiosResponse<EventResponse[]> = await axios.post(
      '/Discovery/GetWhatsNew',
      filters
    );

    return response.data;
  },
  /**
   * Get all events filtered by user preferences
   * @param filters
   * @returns EventResponse
   */
  getEvents: async (filters: DiscoveryFilterRequest) => {
    const response: AxiosResponse<EventResponse[]> = await axios.post(
      '/Discovery/GetEventsOnly',
      filters
    );

    return response.data;
  },
  /**
   * Get Events by category
   * @param filters
   * @returns EventsByCategoryResponse[]
   */
  getEventsByCategory: async (filters: DiscoveryFilterRequest) => {
    const response: AxiosResponse<EventsByCategoryResponse[]> =
      await axios.post('/Discovery/GetGigsByCategory', filters, {
        withCredentials: true
      });

    return response.data;
  },
  /**
   * Get clips filtered by specific settings
   * @param filters
   * @returns GetDiscoveryClipsResponse
   */
  getClips: async (filters: DiscoveryFilterRequest) => {
    const response: AxiosResponse<GetDiscoveryClipsResponse> = await axios.post(
      '/Discovery/GetClips',
      filters
    );

    return response.data;
  },
  /**
   * Get this week's events
   * @param filters
   * @returns EventResponse[]
   */
  getThisWeekEvents: async (filters: DiscoveryFilterRequest) => {
    const response: AxiosResponse<EventResponse[]> = await axios.post(
      '/Discovery/GetEventsThisWeek',
      filters
    );

    return response.data;
  },
  /**
   * Get this month's events
   * @param filters
   * @returns EventResponse[]
   */
  getThisMonthEvents: async (filters: DiscoveryFilterRequest) => {
    const response: AxiosResponse<EventResponse[]> = await axios.post(
      '/Discovery/GetEventsThisMonth',
      filters
    );

    return response.data;
  },
  /**
   * Get Most Trending Events
   * @param filters
   * @returns EventResponse[]
   */
  getMostTrendingEvents: async (skip: number = 0, take: number = 12) => {
    const response: AxiosResponse<EventResponse[]> = await axios.get(
      '/Discovery/GetMostTrendingEvents?skip=' + skip + '&take=' + take,
      {
        headers: {
          'Api-Version': 2
        }
      }
    );

    return response.data;
  },
  /**
   * Get Random Events
   * @param skip
   * @param take
   * @returns EventResponse[]
   */
  getRandomEvents: async (skip: number = 0, take: number = 12) => {
    const response: AxiosResponse<EventResponse[]> = await axios.get(
      '/Discovery/Random?skip=' + skip + '&take=' + take,
      {
        headers: {
          'Api-Version': 2
        }
      }
    );

    return response.data;
  },
  /**
   * Request Guest User Tickets
   * @param phoneNumber
   * @param email
   * @returns Boolean
   */
  requestGuestTickets: async (phoneNumber: string, email: string) => {
    const response: AxiosResponse<boolean> = await axios.get(
      '/Discovery/VerifyFindMyTickets',
      {
        params: {
          phoneNumber,
          email
        },
        headers: {
          'Api-Version': 2
        }
      }
    );
    return response.data;
  },
  /**
   * Get Guest Tickets
   * @param email
   * @param phoneNumber
   * @param verificationCode
   * @returns GetFindMyTicketsResponse
   */
  getGuestTickets: async (
    email: string,
    phoneNumber: string,
    verificationCode: string
  ) => {
    const response: AxiosResponse<GetFindMyTicketsResponse[]> = await axios.get(
      '/Discovery/FindMyTickets',
      {
        params: {
          email,
          phoneNumber,
          verificationCode
        },
        headers: {
          'Api-Version': 2
        }
      }
    );

    return response.data;
  },
  /**
   * Get Banners
   * @returns EventResponse
   */
  getBanners: async () => {
    const response: AxiosResponse<BannerViewModel[]> = await axios.get(
      '/Banners/homepage'
    );

    return response.data;
  },
  getHighlightBanners: async () => {
    const response: AxiosResponse<GetHighlightBannersOutput[]> =
      await axios.get('/Discovery/GetHighLights', {
        headers: {
          'Api-Version': 2
        }
      });

    return response.data;
  },
  getCollectionEvents: async (
    collectionId: string,
    skip: number,
    take: number
  ) => {
    const response: AxiosResponse<GetEventsByHighlightKeyResponse> =
      await axios.get(
        `/Discovery/ByBanner/highlight/${collectionId}?skip=${skip}&take=${take}`,
        {
          headers: {
            'Api-Version': 2
          }
        }
      );

    return response.data;
  },
  getTagEventCollection: async (tagId: string, skip: number, take: number) => {
    const response: AxiosResponse<GetEventsByHashtagKeyResponse> =
      await axios.get(
        `/Discovery/ByBanner/hashtag/${tagId}?skip=${skip}&take=${take}`,
        {
          headers: {
            'Api-Version': 2
          }
        }
      );

    return response.data;
  }
};
